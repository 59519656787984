<template>
    <section class="settings section-offset">
        <h2 class="settings__title section-title">{{ $t("menu.settings") }}</h2>
        <div class="settings-grid">
            <form class="settings__inner settings-form">
                <h3 class="settings__account settings__subtitle">{{ $t("settings.accountSettings") }}</h3>
                <div class="settings-bio">
                    <h4 class="settings-bio__title">{{ $t("settings.pic") }}</h4>
                    <div class="settings-bio__avatar"><img :src="avatarPath" alt="default avatar" /></div>
                    <div class="settings-upload">
                        <label class="settings-upload__label glob-btn" for="upload">
                            <UiLoader v-if="loading" :loading="true" theme="btn" color="#000" />
                            <span v-else>{{ $t("settings.choosePic") }}</span>
                        </label>
                        <input type="file" id="upload" hidden @change="changeAvatar($event)" />
                        <button
                            class="settings-upload__delete btn-reset"
                            type="button"
                            @click="removeAvatar"
                            v-if="hasAvatar"
                        >
                            <svg-vue icon="trashbox" />
                            {{ $t("settings.deletePic") }}
                        </button>
                    </div>
                </div>
                <!-- <h3 class="settings-transfer__title settings__subtitle">{{ $t("settings.transferAccount") }}</h3>
                <div class="settings-transfer">
                    <h4 class="settings-transfer__info settings-bio__title">
                        {{ $t("settings.transferInfo") }}
                        <span :content="$t(`settings.transferTooltip`)" v-tippy>
                            <svg-vue icon="info" />
                        </span>
                    </h4>
                    <div class="settings-transfer-inner">
                        <input
                            type="text"
                            class="settings-transfer__input input-reset"
                            v-model="newAddress"
                            :placeholder="$t(`settings.walletAddress`)"
                        />
                        <button
                            class="settings-transfer__send btn-reset glob-btn"
                            type="button"
                            @click="handleTransferClick"
                        >
                            {{ $t("settings.transferButton") }}
                        </button>
                    </div>
                </div> -->
            </form>
        </div>
    </section>
</template>

<script>
import pageReadyByConnect from "@/mixin/pageReadyByConnect"
import { $api } from "@/plugins/Axios"
import { mapActions, mapGetters } from "vuex"
import { createImageHref } from "@/helpers/images"
import { isHashAddress } from "@/helpers/user"

export default {
    name: "Settings",

    data: () => ({
        avatar: undefined,
        newAddress: "",
        user: undefined,
        loading: false,
    }),
    async mounted() {
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    mixins: [pageReadyByConnect],
    methods: {
        ...mapActions("user", ["getUserByField"]),
        async updateUser() {
            this.loading = true
            await this.getUserByField({ account: this.getAccount })
                .then(({ users }) => {
                    this.loading = false
                    this.user = users
                })
                .catch((err) => {
                    this.loading = false
                    this.$swal(err.message)
                })
        },
        async pageReady() {
            await this.updateUser()
        },
        async hasRights() {
            let isLegit = true
            await this.initAuth().catch((err) => {
                isLegit = false
            })

            return isLegit
        },
        async changeAvatar(event) {
            let hasAuth = await this.hasRights()
            if (!hasAuth) return

            this.loading = true
            this.avatar = event.target.files[0]
            let formData = new FormData()
            formData.append("file", this.avatar)
            formData.append("user_id", this.user.id)
            $api.post("users/methods/setavatar", formData)
                .then(() => {
                    this.updateUser()
                })
                .catch((err) => {
                    this.loading = false
                    this.$swal(err.message)
                })
        },
        async removeAvatar() {
            let hasAuth = await this.hasRights()
            if (!hasAuth) return

            this.loading = true
            $api.delete(`users/methods/remove-avatar/${this.user.id}`)
                .then(() => {
                    this.updateUser()
                })
                .catch((err) => {
                    this.loading = false
                    this.$swal(err.message)
                })
        },
        async handleTransferClick() {
            if (!this.newAddress.trim()) return

            if (isHashAddress(this.newAddress)) {
                const modalRes = await this.$swal
                    .fire({
                        showCancelButton: true,
                        confirmButtonText: this.$t("confirm"),
                        cancelButtonText: this.$t("cancel"),
                        text:
                            this.$t("settings.transferAccountConfirmation.partOne") +
                            " " +
                            this.newAddress +
                            "? " +
                            this.$t("settings.transferAccountConfirmation.partTwo"),
                    })
                    .then((result) => {
                        return result.isConfirmed
                    })

                if (modalRes) {
                    await this.startTransfer()
                }
            } else {
                this.$swal(this.$t("settings.transferAccountConfirmation.wrongFormat"))
            }
        },
        async startTransfer() {
            await this.transferAccount({ to: this.newAddress })
        },
        ...mapActions("crypto", ["transferAccount", "initAuth"]),
    },
    computed: {
        ...mapGetters("crypto", ["getAccount", "isConnected"]),
        hasAvatar() {
            return this.user?.avatar
        },
        avatarPath() {
            return createImageHref(this.user?.avatar)
        },
    },
}
</script>
